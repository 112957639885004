import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loader-spinner-full-screen',
  templateUrl: './spinner-full-screen.component.html',
  styleUrls: ['./spinner-full-screen.component.scss']
})
export class LoaderSpinnerFullScreenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
