import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loader-spinner-navbar',
  templateUrl: './spinner-navbar.component.html',
  styleUrls: ['./spinner-navbar.component.scss']
})
export class LoaderSpinnerNavbarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
